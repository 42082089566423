// Add anything custom here -- mixins, variable overrides.
// by adding things here instead of overriding existing .LESS files, it makes merging changes on product updates easier

//font awesome solid doesn't work so this is a fix
.fas {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 900; }

h1{
  font-size: 2rem;
  font-weight: 600;
}

.home-title{
  padding-left: $grid-gutter-width / 2;

}

h2,h3{
  color: $color-primary;
  font-weight: 600;
}

.filter-block,.search-block{
  padding-left: 0;
}
.search-block{
  padding-right: 0;
}


.member-infos {
  .infos-left{
    .infos-icom{
      font-size: 1rem;
    }
  }
  .infos-right{
    /*background: linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.9),
                    rgba(255, 255, 255, 0.9),
                    #3CC13B
    );*/
    font-size: 1rem;
    a:hover{
      cursor: pointer;
    }
  }
}


.comment-row{
  min-height: 7rem;

  .comment-actions{
    .comment-edit,.comment-delete{
      width: 55px;
      height: 45px;
    }
  }
}

@media print {
  .print-hide{
    display: none;
  }
  .modal {
    position: relative;
  }
}